var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "site-main",
    },
    [
      _c("div", { staticClass: "resume-upload-wrap" }, [
        _c(
          "div",
          { staticClass: "resume-upload-container" },
          [
            _c("h3", { staticClass: "main-title" }, [
              _vm._v(
                _vm._s(_vm.isEdit ? "编辑" : "上传") +
                  _vm._s(_vm.isPersonalCreate ? "个人" : "企业") +
                  "简历"
              ),
            ]),
            _c("div", { staticClass: "main-pannel pannel-topbar" }, [
              !_vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "oprate-wrap" },
                    [
                      _c("span", [_vm._v("上传类别：")]),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.handleUploadType },
                          model: {
                            value: _vm.uploadType,
                            callback: function ($$v) {
                              _vm.uploadType = $$v
                            },
                            expression: "uploadType",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "/resumeUpload/singleUpload" } },
                            [_vm._v("单份上传")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "/resumeUpload/batchUpload" } },
                            [_vm._v("批量上传")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "/resumeUpload/excelUpload" } },
                            [_vm._v("Excel上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.path == "/resumeUpload/singleUpload"
                ? _c(
                    "div",
                    { staticClass: "oprate-wrap" },
                    [
                      _c("span", [_vm._v("上传方式：")]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handlePaste },
                        },
                        [_vm._v("粘贴简历原文")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "upload-file-btn",
                          attrs: { type: "primary", size: "mini" },
                        },
                        [_vm._v("上传简历附件")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.uploadType != "/resumeUpload/excelUpload" && !_vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "oprate-wrap" },
                    [
                      _c("span", [_vm._v("简历来源：")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "source-from-select",
                          attrs: {
                            value: _vm.sourceFrom,
                            placeholder: "请选择",
                            size: "mini",
                          },
                          on: { change: _vm.handleSourceFrom },
                        },
                        _vm._l(_vm.sourceOptions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("router-view", { ref: "viewPort" }),
            _vm.showSingleUploadFooter
              ? _c(
                  "div",
                  { staticClass: "resume-footer" },
                  [
                    _c("el-button", { on: { click: _vm.handleCancel } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSure },
                      },
                      [_vm._v("提交")]
                    ),
                    _c("span", { staticClass: "radio-label" }, [
                      _vm._v("打开后"),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.afterOpenRadio,
                          callback: function ($$v) {
                            _vm.afterOpenRadio = $$v
                          },
                          expression: "afterOpenRadio",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("打开我的人才池"),
                        ]),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("查看简历"),
                        ]),
                        _c("el-radio", { attrs: { label: "3" } }, [
                          _vm._v("继续上传"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }