<template>
    <div class="site-main" v-loading="loading">
        <div class="resume-upload-wrap">
        <div class="resume-upload-container">
            <h3 class="main-title">{{isEdit ? '编辑' : '上传' }}{{isPersonalCreate?'个人':'企业'}}简历</h3>
            <div class="main-pannel pannel-topbar">
                <div class="oprate-wrap" v-if="!isEdit">
                    <span>上传类别：</span>
                    <el-radio-group
                        v-model="uploadType"
                        size="mini"
                        @change="handleUploadType"
                    >
                        <el-radio-button label="/resumeUpload/singleUpload"
                            >单份上传</el-radio-button
                        >
                        <el-radio-button label="/resumeUpload/batchUpload"
                            >批量上传</el-radio-button
                        >
                        <el-radio-button label="/resumeUpload/excelUpload"
                            >Excel上传</el-radio-button
                        >
                    </el-radio-group>
                </div>
                <div class="oprate-wrap" v-if="$route.path=='/resumeUpload/singleUpload'">
                    <span>上传方式：</span>
                    <el-button type="primary" size="mini" @click="handlePaste"
                        >粘贴简历原文</el-button
                    >
                    <el-button
                        type="primary"
                        class="upload-file-btn"
                        size="mini"
                        >上传简历附件</el-button
                    >
                </div>
                <div class="oprate-wrap" v-if="uploadType != '/resumeUpload/excelUpload' && !isEdit">
                    <span>简历来源：</span>
                    <el-select
                        :value="sourceFrom"
                        placeholder="请选择"
                        size="mini"
                        @change="handleSourceFrom"
                        class="source-from-select"
                    >
                        <el-option
                            v-for="item in sourceOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <router-view ref="viewPort"></router-view>

            <!-- <footer-container></footer-container> -->

            <div class="resume-footer" v-if="showSingleUploadFooter">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="handleSure">提交</el-button>

                <span class="radio-label">打开后</span>
                <el-radio-group v-model="afterOpenRadio">
                    <el-radio label="1">打开我的人才池</el-radio>
                    <el-radio label="2">查看简历</el-radio>
                    <el-radio label="3">继续上传</el-radio>
                </el-radio-group>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import FooterContainer from '@/hr/component/footer.vue';
import { mapState, mapGetters, mapActions } from "vuex";
import { getUrlParam } from '#/js/util/tools.js';

const sourceOptions = [
    "活动运营",
    "猎头市场分享",
    "转介绍",
    "朋友介绍",
    "list",
    "自投",
    "领英",
    "脉脉",
    "猎聘",
    "智联卓聘",
    "智联",
    "拉勾",
    "51job",
    "其它"
];
export default {
    name: "resumeUpload",
    components: {
        FooterContainer,
    },
    data() {
        return {
            uploadType: this.$route.path, //上传类型
            sourceOptions: sourceOptions,
            isPersonalCreate: true,
            afterOpenRadio: '1',
            isFold: false,
        };
    },
    mounted() {
        if(!this.isAdministrator && !this.isTalentPermission){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        // 如果有上一次打开之后操作的保存记录就恢复
        const _latestAfterHold = localStorage.getItem('latestAfterHold') || '';
        if(_latestAfterHold) {
            this.afterOpenRadio = _latestAfterHold;
        }

        this.isPersonalCreate = _getQueryString('type')=="0"?true:false;
        this.isFold = localStorage.getItem('isFoldSiderbar') ? JSON.parse(localStorage.getItem('isFoldSiderbar')) : false;
    },
    watch: {
        '$route.path': function(val) {
            this.uploadType = val;
        }
    },
    methods: {
        handleUploadType(val) {
            this.$router.push({
                path: val
            });
        },
        handlePaste() {
            this.invokeSetPastDialogVisible(true);
        },
        handleSourceFrom(val){
            this.invokeSetSourceFrom(val);
        },
        handleCancel() {
            this.$refs['viewPort']?.cancel();
        },
        handleSure() {
            this.$refs['viewPort']?.validBeforeSubmit(false);
        },
        ...mapActions("resumeUpload", [
            "invokeShowLoading",
            "invokeHideLoading",
            "invokeSetSourceFrom",
            "invokeSetPastDialogVisible"
        ])
    },
    computed: {
        ...mapGetters("resumeUpload", {
            loading: "loading",
            sourceFrom: "sourceFrom"
        }),
        isEdit() {
            return _getQueryString('candidateId');
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        
        isTalentPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Talent');
        },
        showSingleUploadFooter() {
            return this.$route.path.includes('resumeUpload/singleUpload') && !getUrlParam('candidateId');
        },
    }
};
</script>
<style lang="scss">
.site-main {
    .resume-upload-container {
        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none; /* 设置比默认滚动条宽度更窄的宽度 */
        .el-button {
            height: 28px;
            line-height: 28px;
        }
    }
}
</style>
<style lang="scss" scoped>
.site-main {
    height: 100%;
    // padding: 20px;
    padding: 20px 20px 0;
    height: calc(100% - 60px);
    box-sizing: border-box;
    .resume-upload-wrap{
        height: 100%;
        overflow-y: auto;
        width: 100%;
        scrollbar-width: none; /* 设置比默认滚动条宽度更窄的宽度 */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .resume-upload-container {
        max-width: 1200px;
        margin: 0 auto;
        border-radius: 8px;
        padding-bottom: 60px;

        .main-title {
            // margin-bottom: 30px;
            font-size: 18px;
            color: #444;
            font-weight: bold;
            padding: 20px;
            margin: 0;
            background-color: #fff;
            border-radius: 8px 8px 0 0;
        }
        .main-pannel {
            background-color: #fff;
            // margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            // border-radius: 8px 8px 0 0;
        }
        .pannel-topbar {
            padding: 15px 20px;
            margin-bottom: 20px;
            display: flex;
            border-radius: 0 0 8px 8px;
            .oprate-wrap {
                margin-right: 40px;
            }
            label {
                margin-bottom: 0;
            }
            .upload-file-btn {
                opacity: 0;
            }
        }
        .upload-file-btn {
            background: #4a90e2;
            border-color: #4a90e2;
        }
        .source-from-select{
            width: 240px;
        }
        .el-button{
            min-width: 54px;
        }

        .resume-footer{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            // bottom: -20px;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 14px;
            color: #999;
            background-color: white;
            z-index: 12;
            text-align: center;
            padding-left: 60px;
            .el-button{
                width: 100px;
                &:first-child{
                    margin-right: 20px;
                }
            }
            .radio-label{
                margin: 0 20px 0 40px;
                color: #333;
            }
        }
        .isFold{
            left: 70px;
        }

    }
}
</style>